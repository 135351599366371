<template>
  <div>
    <b-tabs>
      <b-tab active title="English">
        <b-card title="Booking Counter Details">
          <validation-observer ref="basicValidation">
            <b-form>
              <b-row>
                <b-col md="6">
                  <b-form-group label="Branch Title">
                    <validation-provider
                      #default="{ errors }"
                      name="Branch Title"
                      rules="required"
                    >
                      <b-form-input
                        v-model="counter.branch"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Branch Title"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Address">
                    <b-form-input
                      v-model="counter.address"
                      placeholder="Branch Address"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Phone">
                    <b-form-input v-model="counter.phone" placeholder="Phone" />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Fax">
                    <b-form-input
                      v-model="counter.fax"
                      placeholder="Fax number"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="City">
                    <b-form-input v-model="counter.city" placeholder="City" />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-tab>

      <b-tab title="Hindi">
        <b-card title="Booking Counter Details">
          <b-form>
            <b-row>
              <b-col md="6">
                <b-form-group label="Branch Title">
                  <b-form-input
                    v-model="counter.translation.hi.branch"
                    placeholder="Branch Title"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Address">
                  <b-form-input
                    v-model="counter.translation.hi.address"
                    placeholder="Branch Address"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Phone">
                  <b-form-input
                    v-model="counter.translation.hi.phone"
                    placeholder="Phone"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Fax">
                  <b-form-input
                    v-model="counter.translation.hi.fax"
                    placeholder="Fax number"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="City">
                  <b-form-input
                    v-model="counter.translation.hi.city"
                    placeholder="City"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-tab>

      <b-tab title="Marathi">
        <b-card title="Booking Counter Details">
          <b-form>
            <b-row>
              <b-col md="6">
                <b-form-group label="Branch Title">
                  <b-form-input
                    v-model="counter.translation.mr.branch"
                    placeholder="Branch Title"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Address">
                  <b-form-input
                    v-model="counter.translation.mr.address"
                    placeholder="Branch Address"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Phone">
                  <b-form-input
                    v-model="counter.translation.mr.phone"
                    placeholder="Phone"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Fax">
                  <b-form-input
                    v-model="counter.translation.mr.fax"
                    placeholder="Fax number"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="City">
                  <b-form-input
                    v-model="counter.translation.mr.city"
                    placeholder="City"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-tab>
    </b-tabs>

    <b-card>
      <b-col class="align-self-center">
        <b-button
          @click="createBookingCounter"
          size="lg"
          block
          variant="primary"
          >Add Booking Counter</b-button
        >
      </b-col>
    </b-card>

    <b-card title="Upload Bulk (CSV)">
      <b-form-file
        v-model="file"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
      />

      <b-card-text v-if="file" class="my-1">
        Selected file: <strong>{{ file ? file.name : "" }}</strong>
      </b-card-text>

      <div class="text-right">
        <b-button @click="uploadCSV" variant="primary">Upload File</b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";
import { getUserToken } from "@/auth/utils";

import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BButton,
  BTabs,
  BTab,
  BFormTextarea,
  BFormFile,
  BCardText,
} from "bootstrap-vue";
import axios from "axios";
export default {
  directives: {
    Ripple,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BTabs,
    BTab,
    BFormGroup,
    BButton,
    BFormTextarea,
    BFormFile,
    BCardText,
  },
  data() {
    return {
      required,
      file: [],
      uploadErrors: [],
      counter: {
        branch: "",
        address: "",
        phone: "",
        fax: "",
        city: "",
        translation: {
          hi: {
            branch: "",
            address: "",
            phone: "",
            fax: "",
            city: "",
          },
          mr: {
            branch: "",
            address: "",
            phone: "",
            fax: "",
            city: "",
          },
        },
      },
    };
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    createBookingCounter() {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/org/bookingCounter/create`,
          this.counter,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          this.makeToast(
            "success",
            "Success",
            `${response.data.branch} has been created successfully!`
          );
        })
        .catch((error) => {
          console.log(error.response);
          this.makeToast(
            "danger",
            "Error",
            "Error while adding the Booking Counter!"
          );
        });
    },
    uploadCSV() {
      const csvFormData = new FormData();
      csvFormData.append("csv-data", this.file);
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/org/bookingCounter/save`,
          csvFormData,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          if (response.data.length) {
            this.uploadErrors = response.data;
            this.makeToast(
              "danger",
              "Error",
              "Errors found while uploading data!"
            );
          } else {
            this.makeToast("success", "Success", `Uploaded successfully!`);
          }
        });
    },
  },
};
</script>

<style>
</style>